@import "../node_modules/@identy/identy-ocr/dist/identy-ocr-style.css";
@import "../node_modules/@identy/identy-face/dist/identy-face-style.css";
@import "../node_modules/@identy/identy-finger/dist/identy-finger-style.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

.login{
    height: 56vh;
    width: 100vw;
    /* background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.357), rgba(0, 0, 0, 0.73)), url('./vllkyt55v3qvumbh9.jpg') !important; */
    background-position: center;
    /* background-size: contain; */
    background-repeat: no-repeat;
}

.top{
    position: relative;
    z-index: -1;
    height: 40vh;
    width:  100vw;
}

.lower {
    height: 60vh;
    width: 100vw;
    background-color: black;
    border-top-right-radius: 100px;
}

.login h1{
    position: absolute;
    z-index: 1;
    /* top: 50 !important; */
    color: white;
}



/* index page */
.index-nav {
    background-color: #FFC502;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;

}

button{
    color: #FFC502 !important;
}

.actions{
    height: 70vh;
    /* background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 35%, rgba(146, 137, 136, 0.11457923012955185) 100%), url('./banner-home-xtra.jpg'); */
    /* background-position: 100% -10%; */
    background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
}

.menu {
    /* background: linear-gradient( rgba(0, 0, 0, 0.655)); */
}

.index-page {
    /* height: 100vh; */
        /* background-color: #e5e5f7;
            opacity: 1;
            background-image: linear-gradient(135deg, #c6c7db 25%, transparent 25%), linear-gradient(225deg, #c6c7db 25%, transparent 25%), linear-gradient(45deg, #c6c7db 25%, transparent 25%), linear-gradient(315deg, #c6c7db 25%, #e5e5f7 25%);
            background-position: 10px 0, 10px 0, 0 0, 0 0;
            background-size: 20px 20px;
            background-repeat: repeat; */

}

.app-bg-color{
    background: #FFC502;
}

.new-sim-reg {
    height: 100vh;
    /* background-color: #e5e5f7;
        opacity: 1;
        background-image: linear-gradient(135deg, #c6c7db 25%, transparent 25%), linear-gradient(225deg, #c6c7db 25%, transparent 25%), linear-gradient(45deg, #c6c7db 25%, transparent 25%), linear-gradient(315deg, #c6c7db 25%, #e5e5f7 25%);
        background-position: 10px 0, 10px 0, 0 0, 0 0;
        background-size: 20px 20px;
        background-repeat: repeat; */
}

.top-curve{
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
}

body{
    /* background-image: url('./mtn_bg.png'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.pointer{
    cursor: pointer;
}

.identy_training_container {
    background-color: #FFC502;
}